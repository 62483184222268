import View from '@ckeditor/ckeditor5-ui/src/view';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
// import ViewCollection from '@ckeditor/ckeditor5-ui/src/viewcollection';
import checkIcon from '@ckeditor/ckeditor5-core/theme/icons/check.svg';
import cancelIcon from '@ckeditor/ckeditor5-core/theme/icons/cancel.svg';
import LabeledInputView from '@ckeditor/ckeditor5-ui/src/labeledinput/labeledinputview';
import InputTextView from '@ckeditor/ckeditor5-ui/src/inputtext/inputtextview';
import KeystrokeHandler from '@ckeditor/ckeditor5-utils/src/keystrokehandler';
import '../../theme/lpformulaform.css';

export default class LPFormulaFormView extends View {
	/**
 * @inheritDoc
 */
	constructor( editor ) {
		super( editor.locale );
		// create formula input
		this.formulaInputView = this._createFormulaInput();
		// create save button
		this.saveButtonView = this._createButton( 'Opslaan', 'ck-button-save', 'lpformulasave', checkIcon );
		// create cancel icon
		this.cancelButtonView = this._createButton( 'Annuleren', 'ck-button-cancel', 'lpformulacancel', cancelIcon );

		this.keystrokes = new KeystrokeHandler();

		this.children = this._createFormChildren();

		const classList = [ 'ck', 'ck-formula-form' ];

		this.setTemplate( {
			tag: 'form',

			attributes: {
				class: classList,

				// https://github.com/ckeditor/ckeditor5-link/issues/90
				tabindex: '-1'
			},

			children: this.children
		} );
	}

	render() {
		super.render();

		// Start listening for the keystrokes coming from #element.
		this.keystrokes.listenTo( this.element );
	}

	_createFormulaInput() {
		const labeledInput = new LabeledInputView( this.locale, InputTextView );

		labeledInput.label = '';
		labeledInput.inputView.placeholder = 'KaTeX formula...';

		return labeledInput;
	}

	_createButton( btnLabel, btnClass, eventName, btnIcon ) {
		const button = new ButtonView( this.locale );
		button.set( {
			label: btnLabel,
			tooltip: true,
			withText: false,
			icon: btnIcon
		} );

		button.extendTemplate( {
			attributes: {
				class: btnClass
			}
		} );

		if ( eventName ) {
			button.delegate( 'execute' ).to( this, eventName );
		}

		return button;
	}

	_createFormChildren() {
		const children = this.createCollection();

		children.add( this.formulaInputView );
		children.add( this.saveButtonView );
		children.add( this.cancelButtonView );

		return children;
	}
}
